import { Link } from 'react-router-dom'
import myPicture from '../../assets/images/profilePicture.png'
import './myself.scss'

const MySelf = () => {
    return (
        <>
            <div className="myself">
                <div className="myself__content">
                    <h2>Qui suis-je ?</h2>
                    <div>
                        <p>
                            Web Designer depuis 2019, j’ai aussi été amenée à
                            travailler en direction artistique et graphisme
                            print.
                        </p>
                        <p>
                            Récemment formée à l'UX Design j'ai à coeur de
                            développer continuellement mes connaissances.
                        </p>
                        <p>J’ai hâte de faire votre connaissance !</p>
                    </div>

                    <Link className="linkBtn link-mbottom" to="/curriculum">
                        Voir le CV
                    </Link>
                    <a
                        className="linkBtn link-mbottom"
                        href="https://www.linkedin.com/in/melanie-chabrol/details/recommendations/?detailScreenTabIndex=0"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Voir mes recommandations
                    </a>
                </div>
                <img
                    className="myself__picture"
                    src={myPicture}
                    alt="Mélanie Chabrol"
                />
            </div>
        </>
    )
}

export default MySelf
