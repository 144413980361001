import PageTitle from '../components/molecules/pageTitle'
import './timeline.scss'

const TimelinePage = () => {
    return (
        <main className="page-anim">
            <PageTitle
                title="Ma Timeline"
                subtitle="Mes objectifs passés, présents et futurs"
            />
            <section className="timeline-component">
                <h2>Futur</h2>
                <div>
                    <h3>
                        Continuer de développer mes compétences dans l’UI UX
                        Design
                    </h3>
                    <p>
                        Continuer de pratiquer afin de développer mes nouvelles
                        compétences en UX, et continuer d’alimenter celles en
                        UI, dans le but de pouvoir m’épanouir dans ce domaine.
                    </p>
                </div>
            </section>
            <section className="timeline-component">
                <h2>Présent</h2>
                <div>
                    <h3>
                        Formation Open Classrooms - UX Design Création d’un
                        futur projet professionnel
                    </h3>
                    <p>
                        Objectif : Prendre un virage dans le domaine du design
                        et me ré-orienter vers un cursus UI, en acquérant une
                        maîtrise solide de l’UX Design pour compléter mes
                        compétences. En parallèle : Collaboration active avec un
                        développeur front-end pour la réalisation d’un portfolio
                        de développeur.
                    </p>
                </div>
            </section>
            <section className="timeline-component">
                <h2>Passé</h2>
                <div>
                    <h3>Directrice artistique et Web Designer (IDKIDS)</h3>
                    <p>
                        Conception des nouvelles campagnes de communication &
                        direction des shoots photo/vidéo. Intentions graphiques
                        et visuelles, projection en 360, réalisation des
                        supports principaux de lancement, retouches photos,
                        castings des enfants : En adéquation et respect du
                        positionnement et de la stratégie de la marque, en
                        maintenant son image et son expression graphique. En
                        collaboration avec des équipes variées, marketing, CR,
                        CM, graphistes, UX UI designers, Intégrateurs web,
                        achats d'art, photographes, vidéastes, stylistes,
                        coiffeurs & maquilleurs, studios de retouches.
                    </p>
                </div>
            </section>
        </main>
    )
}

export default TimelinePage
