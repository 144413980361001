import { useState } from 'react'
import SkillBar from '../molecules/skillBar'
import './skillSection.scss'

const SkillSection = () => {
    const [activeSkillBar, setActiveSkillBar] = useState('Soft Skills')

    const handleSkillBarClick = (title) => {
        setActiveSkillBar((prevTitle) => (prevTitle === title ? null : title))
    }

    return (
        <section className="skill-section">
            <SkillBar
                title="Soft Skills"
                color="#E48471"
                collapsed={activeSkillBar !== 'Soft Skills'}
                onClick={() => handleSkillBarClick('Soft Skills')}
            >
                <ul className="ulist">
                    {/* <li>Ecoute active et curiosité</li>
                    <li>Patience et adaptabilité</li>
                    <li>Rigueur et organisation</li>
                    <li>Respect et confiance</li> */}
                    <li>
                        <strong className="job-title">
                            Compétences en communication :
                        </strong>{' '}
                        Exécution rapide, rigueur dans les détails, résultats de
                        qualité, optimisation des process, création de template
                        pour accélérer la production.
                    </li>
                    <li>
                        <strong className="job-title">
                            Gestion du temps et des priorités :
                        </strong>{' '}
                        Capacité à gérer plusieurs projets en simultanés, à
                        tenir des délais stricts. Je planifie, priorise et
                        structure les tâches. Workflow assuré et sans accroc.
                    </li>
                    <li>
                        <strong className="job-title">Adaptabilité :</strong> Je
                        m’adapte à vos besoins. Je peux me montrer très
                        inventive et astucieuse face à n’importe quel problème !
                    </li>
                    <li>
                        <strong className="job-title">Authenticité :</strong>{' '}
                        Transparence et authenticité, feedbacks honnêtes et
                        constructifs, Communication claire. Engagement à rester
                        fidèle aux valeurs du projet.
                    </li>
                </ul>
            </SkillBar>
            <SkillBar
                title="Hard Skills"
                color="#CBB4E1"
                collapsed={activeSkillBar !== 'Hard Skills'}
                onClick={() => handleSkillBarClick('Hard Skills')}
            >
                <ul className="ulist">
                    <li>Création de personas</li>
                    <li>Wireframing</li>
                    <li>Tests utilisateurs</li>
                    <li>Prototypages intéractifs (Figma)</li>
                    <li>Audit UX</li>
                    <li>
                        Techniques d'intégration (HTML, CSS, Responsive &
                        Adaptative Design, Accessibilité web)
                    </li>
                    <li>Suite Adobe</li>
                    <li>Dreamweaver</li>
                </ul>
            </SkillBar>
            <SkillBar
                title="Intérêts"
                color="#EA8D00"
                collapsed={activeSkillBar !== 'Intérêts'}
                onClick={() => handleSkillBarClick('Intérêts')}
            >
                <ul className="ulist">
                    <li>Retouche photo</li>
                    <li>Montage vidéo</li>
                    <li>Open AI (Prompting)</li>
                    <li>Illustration</li>
                    <li>Crochet</li>
                    <li>Musique, chant et basse</li>
                    <li>Jeux vidéos</li>
                </ul>
            </SkillBar>
        </section>
    )
}

export default SkillSection
