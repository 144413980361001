import './CV.scss'
import PageTitle from '../components/molecules/pageTitle'
import ShowMore from '../components/molecules/showMore'
import SkillBar from '../components/molecules/skillBar'
import { useState } from 'react'
import BigCat from '../components/atoms/bigCat'
import SmolCat from '../components/atoms/SmolCat'

const CVPage = () => {
    const [activeSkillBar, setActiveSkillBar] = useState('Expériences')

    const handleSkillBarClick = (title) => {
        setActiveSkillBar((prevTitle) => (prevTitle === title ? null : title))
    }
    return (
        <main className="cvPage page-anim">
            <PageTitle
                title="CV"
                subtitle="Parcours et savoir-faire, all-in-one !"
            />
            <div className="cvContent">
                <div className="cvContent__left">
                    <SkillBar
                        title="Expériences"
                        color="#E48471"
                        collapsed={activeSkillBar !== 'Expériences'}
                        reverse={true}
                        onClick={() => handleSkillBarClick('Expériences')}
                    >
                        <ul className="ulist centered">
                            <li>
                                <strong className="job-title">
                                    HappyWool depuis 2024:
                                </strong>{' '}
                                <br /> Web Designer / Intégratrice / UX
                                Designer, <br /> Design et intégration de
                                newletters, de supports pour le site B2C et B2B
                                et refonte UX de certaines pages du site web
                            </li>
                            <li>
                                <strong className="job-title">
                                    Openclassrooms depuis 2024 :
                                </strong>{' '}
                                Formation UX Designer, niveau 6. Mener des
                                études utilisateurs, Collaborer avec les équipes
                                de développement, Créer des maquettes et
                                prototypes, Organiser et piloter des campagnes
                                de tests auprès des utilisateurs, Réaliser des
                                Audits
                            </li>
                            <li>
                                <strong className="job-title">
                                    BILLYBELT 2023
                                </strong>{' '}
                                Directrice artistique
                            </li>
                            <p className="id-kids">
                                <span>IDKIDS</span> depuis 2019 :
                            </p>
                            <li>
                                <strong className="job-title">
                                    Directrice Artistique / Web Designer
                                </strong>{' '}
                                (Catimini, Absorba, Chipie, Lili Gaufrette)
                            </li>
                            <li>
                                <strong className="job-title">
                                    Directrice Artistique / Web Designer
                                </strong>
                                (Okaïdi-Obaïbi)
                            </li>
                            <li>
                                <strong className="job-title">
                                    Graphiste maquettiste, puis Directrice
                                    Artistique
                                </strong>{' '}
                                (Oxybul)
                            </li>
                            <li>
                                <strong className="job-title">
                                    Illustratrice et Graphiste
                                </strong>{' '}
                                (N’joy & Rigolo Comme La Vie)
                            </li>
                        </ul>
                    </SkillBar>
                    <SkillBar
                        title="Formations"
                        color="#CBB4E1"
                        collapsed={activeSkillBar !== 'Formations'}
                        reverse={true}
                        onClick={() => handleSkillBarClick('Formations')}
                    >
                        <ul className="ulist centered">
                            <li>
                                UX Designer - Open Classrooms - Niveau 6 bac+¾
                            </li>
                            <li>Certification Niveau 5 (Bac+2) Designer Web</li>
                            <li>1 an Publicité à l’ESA St Luc, Tournai</li>
                            <li>1 an Graphisme à l’ESA St Luc, Tournai</li>
                            <li>
                                1 an MANAA à Maestris Auvergne Formation,
                                Clermont-Fd
                            </li>
                            <li>
                                1 an Licence d’Anglais à l’Université Blaise
                                Pascal, Clermont-Fd
                            </li>
                            <li>
                                3 ans Bac Pro Artisanat et Métiers d’Art Opt
                                Communication Graphique, Lycée Vercingétorix,
                                Romagnat (63)
                            </li>
                            <li>
                                CAP Artisanat et Métiers d’Art Opt Communication
                                Graphique, Lycée Vercingétorix, Romagnat (63)
                            </li>
                        </ul>
                    </SkillBar>
                </div>
                <div className="cvContent__right">
                    <div className="sf__container">
                        <h3>Savoir-faire</h3>
                        <ul>
                            {/* <li>
                                Maîtrise des outils de conception : - Sketch,
                                Adobe XD, Figma, InVision, etc.
                            </li>
                            <li>
                                Connaissance des principes de conception
                                d'interfaces utilisateur (UI) : - typographie,
                                couleur, hiérarchie, disposition, etc.
                            </li> */}
                            {/* <li>
                                Expérience en recherche utilisateur : -
                                planification et réalisation d'entretiens, de
                                tests d'utilisabilité, d'études de marché et
                                d'analyses de données
                            </li> */}
                            <li>
                                <strong>
                                    Compétences en recherche utilisateur{' '}
                                </strong>
                                <br />
                                (Techniques de recherche qualitatives et
                                quantitatives, Création de personas, mapping des
                                parcours utilisateurs, Tests utilisateurs){' '}
                            </li>
                            <li>
                                <strong>Compétences en conception</strong>
                                <br />
                                (Wireframing, Élaboration de prototypes
                                interactifs, Architecture de
                                l'information,Création d’expériences
                                utilisateur)
                            </li>
                            <li>
                                <strong>Compétences analytiques</strong>
                                <br />
                                (Analyse de données, Audit UX, Tests
                                d'utilisabilité, Évaluations heuristiques)
                            </li>
                            <li>
                                <strong>Compétences techniques</strong>
                                <br />
                                (Logiciels de conception tels que figma,
                                maitrise du responsive design, et de
                                l’accessibilité)
                            </li>
                            {/* <li>Capacité rédactionnelle et scénographique</li>
                            <li className="li--bolded">
                                Capacité à imaginer des concepts créatifs, les
                                développer et les appliquer sous différents
                                supports
                            </li>
                            <li>Capacité à combiner des idées</li>
                            <li className="li--bolded">
                                Méthode de pensée en arborescence (Mind-Mapping)
                            </li>
                            <li>Capacité d’écouter et de noter les besoins</li>
                            <li>Capacité à s’auto-former</li> */}
                        </ul>
                        <div className="aspi-pro">
                            <h3>Aspirations professionnelles :</h3>
                            <p>
                                UX UI Design, spécialisation UI & conception
                                maquettage
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cv__languages">
                <ul>
                    <li>Français natal</li>
                    <li>Anglais courant</li>
                </ul>
                <SmolCat />
                <BigCat />
            </div>
            <ShowMore />
        </main>
    )
}

export default CVPage
